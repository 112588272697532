@keyframes openDrawer {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes closeDrawer {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }

}

.Drawer {
  float: right;
  position: fixed;
  z-index: 20;
  max-width: 500px;
  min-height: 100vh;
  background-color: var(--theme-background-color--default);
  width: 100%;
  transform: translateX(100%);
  right: 0px;
  top: 0px;
  transform: translateX(0);
}

.DrawerHidden {
  display: none;
}

.DrawerOpen {
  animation: openDrawer 0.5s ease-in-out forwards;
}

.DrawerClose {
  animation: closeDrawer 0.5s ease-in-out forwards;
}

@keyframes closeBackdrop {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }

}

@keyframes openBackdrop {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


.Backdrop {
  position: fixed;
  inset: 0;
  background-color: rgba(31, 29, 29, 0.8);
  z-index: 10;
}

.BackdropHidden {
  display: none;
}

.BackdropOpen {
  animation: openBackdrop 0.5s ease-in-out forwards;
  display: block;
}

.BackdropClose {
  animation: closeBackdrop 0.5s ease-in-out forwards;
  display: none;
}