@import "../../../../../styles/breakpoints.scss";

div.container {
  padding: 3rem;
  height: 100%;

  button.buttonFecharModal {
    border: none;
    background-color: transparent;
    cursor: pointer;
    float: right;
  }

  .modalHeading {
    color: var(--theme-text-color--primary);
  }

  .mdnRow {
    height: calc(100% - 3rem);

    .illustrationPart {
      background-image: url('../../../../../assets/ja-sou-cliente/illustrations/api-error.webp');
      background-size: contain;
      background-repeat: no-repeat;
      background-position-x: 20px;

      @media screen and (max-width: $breakpoint-sm) {
        min-height: 300px
      }
    }

    .messagePart {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      @media screen and (min-width: $breakpoint-sm) {
        margin-top: 3rem;
      }

      .apiErrorMessage {
        font-size: 20px;
      }

      .voltarBtn {
        width: fit-content  ;
      }
    }
  }

  // .sectionContainer {
  //   display: flex;
  //   height: calc(100% - 3rem);

  //   @media screen and (max-width: $breakpoint-md) {
  //     flex-direction: column;
  //     gap: 1rem;
  //   }

  //   .apiErrorIllustration {
  //     background-image: url('../../../../../assets/ja-sou-cliente/illustrations/api-error.webp');
  //     background-size: contain;
  //     background-repeat: no-repeat;
  //     height: calc(100% - 3rem);
  //     width: 100%;

  //     @media screen and (max-width: $breakpoint-md) {
  //       max-height: 300px;
  //       width: 100%;
  //     }
  //   }

  //   .messageContainer {
  //     margin: auto 0;

  //     @media screen and (max-width: $breakpoint-md) {
  //       margin-top: 1rem;
  //     }

  //     .apiErrorMessage {
  //       font-size: 20px;
  //     }
  //   }
  // }
}
