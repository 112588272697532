@import "../../../../../styles/breakpoints.scss";

div.container {
  min-height: 100%;
  display: grid;

  grid-template-areas:
    'banner'
    'content';

  grid-template-rows: 170px 1fr;

  @media screen and (min-width: $breakpoint-md) {
    grid-template-areas:
      'banner content';

    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }

  div.banner {
    grid-area: banner;
    background-image: url('../../../../../assets/ja-sou-cliente/ja-sou-cliente-banner-mobile.webp');
    background-size: contain;
    border-radius: 0;
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (min-width: $breakpoint-md) {
      background-image: url('../../../../../assets/ja-sou-cliente/ja-sou-cliente-banner-desktop.webp');
      border-radius: var(--theme-border-radius-sm) 0 0 var(--theme-border-radius-sm);
    }
  }

  div.content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    grid-area: content;
    padding: 1.5rem 2rem 0px 2rem;

    button.buttonFecharModal {
      border: none;
      background: transparent;
      align-self: flex-end;
      padding: 0;

      &:hover {
        cursor: pointer;
      }
    }

    h2.mdnSubtitle {
      color: var(--theme-color-neutral-darkest);
    }

    div.btnsContainer {
      display: flex;
      justify-content: space-between;
    }

    div.warningMessage {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      margin: 1rem 0 1rem 0;

      p {
        line-height: 24px;
      }
    }

    p.errorMessage {
      color: var(--theme-text-color--primary);
    }
  }
}
