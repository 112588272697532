@import "../../../../../styles/breakpoints.scss";

div.container {
  padding: 1rem;

  @media screen and (min-width: $breakpoint-md) {
    padding: 3rem;
  }

  height: 100%;

  button.buttonFecharModal {
    border: none;
    background-color: transparent;
    cursor: pointer;
    float: right;
  }

  div.content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: $breakpoint-md) {
      padding: 3rem 3rem;
    }

    .mdnRow {
      height: calc(100% - 3rem);

      .illustrationPart {
        background-image: url('../../../../../assets/ja-sou-cliente/illustrations/api-error.webp');
        background-size: contain;
        background-repeat: no-repeat;
        background-position-x: 20px;

        @media screen and (max-width: $breakpoint-sm) {
          min-height: 190px;
          align-items: center;
          background-position-x: center;
        }
      }

      .messagePart {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        @media screen and (min-width: $breakpoint-sm) {
          // margin-top: 3rem;
        }

        .apiErrorMessage {
          font-size: 20px;
        }

        .voltarBtn {
          width: fit-content;
        }
      }
    }
  }
}
