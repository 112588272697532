@import "../../../../../styles/breakpoints.scss";

div.container {
  position: relative;
  height: 100%;
  padding: 3rem;

  button.buttonFecharModal {
    border: none;
    background-color: transparent;
    cursor: pointer;
    float: right;
  }

  .modalHeading {
    color: var(--theme-text-color--primary);
  }

  .subtitle {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  section.reenviarCodigo {
    margin-top: 2rem;

    h1 {
      font-size: 1rem;
    }
  }

  .voltarBtn {
    margin-top: 2rem;

    @media screen and (min-width: $breakpoint-md) {
      position: absolute;
      bottom: 3rem;
    }
  }
}
