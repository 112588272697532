.mdn-Modal--sm .mdn-Modal-content {
  height: fit-content;
}

.customMargin {
  margin-top: 30px;
}

.defaultColor {
  background-color: var(--theme-background-color--default);
}

.list-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3rem;
  padding: 12px;

  li a {
    font-size: 12px;
  }
}

.accessibilityItem{
  margin-right: 3px;
}

.mdn-Icon-alto-contraste, .mdn-Icon-pcd {
  margin-right: 5px;
}

.mdn-Menu-top{
  @media screen and (max-width: 992px) {
    display: none;
  }
}

// BlackFriday Theme
.black-friday {
  .mdn-Menu {
    background-color: #252525;

    .mdn-Menu-top {
      background-color: #000000;

      .mdn-Menu-top-list-item-accessibility {
        color: #FFFFFF;
      }
    }
  }

  .mdn-Footer {
    background-color: #000000;
  }
}