.tooltip {
  position: absolute;
  content: "";
  width: 100%;

  border-radius: 0.25rem;
  left: 0;
  top: 62px;
  min-width: calc(100% - 2rem);
  max-width: 600px;
  z-index: 4;
  line-height: var(--theme-font-line-height-xs);
  background-color: var(--theme-color-neutral-darkest);
  padding: var(--theme-spacing-inset-xs);

  &::after {
    content: "";
    position: absolute;
    top: -15px;
    left: calc(50% - 0.5rem);
    border-width: 0.5rem;
    border-style: solid;
    border-color: var(--theme-color-neutral-darkest) transparent transparent transparent;
    transform: rotate(180deg);
    z-index: 5;
  }
}

.checkmark {
  position: absolute;
  top: calc(50% - 8px);
  right: 10px;
  color: (var(--color-support-success-dark));
}

.inputValid {
  border-color: (var(--color-support-success-dark));
}

.checkmarkInvalid {
  position: absolute;
  top: calc(50% - 8px);
  right: 10px;
  color: (var(--color-support-danger-dark));
}