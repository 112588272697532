.mdnHeading {
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}

.closeBtn {
  margin-left: 1rem;
  height: 28px !important;
  width: 28px !important;
}

.mdnBodyInBannerMode {
  margin-top: 0 !important;
  padding-right: 0 !important;
}

.mdnContentInBannerMode {
  padding: 0 !important;
}

.bannerPromocional {
  border-radius: 0 !important;
  border: none !important;
  @media screen and (min-width: 1367px) {
    height: 667px !important;
    width: 787px !important;
  }
  @media screen and (max-width: 1367px) and (max-height: 668px) {
    height: 537px !important;
    width: 634px !important;
  }    
}
