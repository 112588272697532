.EcomFormDrawerContainer {
  background: var(--theme-background-color--default);
  height: 100vh;
  padding: 1.25rem;
  position: relative;

  >span {
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
    cursor: pointer;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--theme-color-neutral-darkest-content);
    transition: opacity 0.2s ease-in-out;

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 1px;
      height: 12px;
      background-color: var(--theme-color-neutral-darkest-content);
    }

    &::before {
      transform: rotate(-45deg);
    }

    &::after {
      transform: rotate(45deg);
    }

    &:hover {
      opacity: 0.6;
    }
  }

  >div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 80px;

    p {
      font-weight: 500;
      color: var(--theme-fonts-color-default);
      line-height: 130%;
      font-family: var(--theme-font-family--Text);

      &:first-of-type {
        font-size: 1.25rem;
      }

      &:last-of-type {
        width: 25ch;
        font-size: 1rem;
      }
    }
  }
}

.EcomFormDrawerForm {
  margin-top: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 300px;

  >div {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  >button {
    font-size: 1rem;
    width: 100%;
    padding: 0.5rem 1.5rem;
    border-radius: 24px;
    border: none;
    cursor: pointer;
    background: var(--theme-background-color-Button--global);
    transition: background 0.2s ease-in-out;

    &:hover {
      background: var(--theme-background-color-Button-hover--global);
    }
  }
}