.inputContainer {
  position: relative;
  width: 100%;

  .loading {
    position: absolute;
    right: 8px;
    top: 17px;
  }

  .mdnIcon {
    position: absolute;
    right: 8px;
    top: 17px;
    color: var(--theme-background-color--primary);
  }
}
