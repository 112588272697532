.EcomNossoSinal {
  &--someModifier {
  } // primary, secondary, sm, md, lg, ...
  &-someChildren {
  } // a internal component that only exists inside "EcomNossoSinal" context. Ex.: "Accordion-box"
}

.subtitulo-nosso-sinal {
  margin-top: var(--theme-spacing-stack-xl);
  margin-bottom: var(--theme-spacing-stack-md);
}

.container {
  margin-bottom: 3rem;
}

.mdn-Row {
  row-gap: 2rem;
}
