@import "../../../../../styles/breakpoints.scss";

div.container {
  padding: 3rem;

  button.buttonFecharModal {
    border: none;
    background-color: transparent;
    cursor: pointer;
    float: right;
  }

  .modalHeading {
    color: var(--theme-text-color--primary);
  }

  section {
    .tentarNovamenteHeading {
      font-size: 20px;
    }

    .conferirOutrasOfertas {
      margin-top: 1rem;
      font-size: 18px;
    }

    .button {
      margin: 1rem 0;
    }
  }

  .errorIllustration {
    background-image: url('../../../../../assets/ja-sou-cliente/illustrations/nao-e-cliente-movel.webp');
    background-size: contain;
    background-repeat: no-repeat;
    height: 189px;
    width: 100%;
    max-width: 319px;
    margin: 1rem 0;
  }
}
