body,
html {
  height: 100%;
}

body {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

@import '../components/EcomMenu/styles.scss';
@import '../components/EcomNossoSinal/styles.scss';
@import '../components/EcomNossoSinalItem/styles.scss';
