@import '../../../../styles//breakpoints.scss';

div.superOferta {
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  height: 100%;
  position: relative;

  img.banner {
    background-size: cover;
    border-radius: 0;
    height: 512px;
    width: 100%;
  }

  @media screen and (min-width: $breakpoint-md) {
    img.banner {
      background-position-y: -65px;
      border-radius: 0;
      height: 600px;
      width: 100%;
    }
  }

  button.buttonFecharModal {
    border: none;
    position: absolute;
    top: .5rem;
    right: .5rem;
    padding: .75rem;
    border-radius: 50%;
    background: var(--color-neutral-lightest);
    cursor: pointer;
    z-index: 3;

    &::after, &::before{
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: .7rem;
      height: 0.1rem;
      border-radius: 1rem;
      background: var(--color-brand-primary-medium);
    }
    &::after{
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &::before{
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  .mdn-Swiper{
    width: 100%;
    
    .mdn-SwiperSlide-buttons {
      position: absolute;
      top: calc(50% - 45px);
      width: 100%;
      display: flex;
      justify-content: space-between;
          
      .mdn-Swiper-button-prev {
        height: 90px;
        width: 45px;
        border-radius: 0 12rem 12rem 0;
        justify-content: flex-start;
        margin-top: 0;
        opacity: var(--theme-opacity-intense);
      }.mdn-Swiper-button-prev:hover {
        opacity: 1;
      }
    
      .mdn-Swiper-button-next {
        height: 90px;
        width: 45px;
        border-radius: 12rem 0 0 12rem;
        justify-content: flex-end;
        margin-top: 0;
        opacity: var(--theme-opacity-intense);
      }.mdn-Swiper-button-next:hover {
        opacity: 1;
      }
    
      .mdn-Swiper-button-prev, .mdn-Swiper-button-next {
        background-color: var(--theme-color-neutral-dark-clickable);
      }
    
    }
  }
}
div.swiperBanner{
  height: 100%;
  border-radius: 0;
  width: 100%;
  margin: 0;

  .bannerItem {
    height: 100%;
    width: 100%;

    > span {
      width: 100% !important;
    }
  }
}